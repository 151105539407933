<style src="../css/head.css"></style>

<style src="../css/detail.css"></style>

<template>
  <div>
    <Head-section></Head-section>
    <div v-if="iszh">
    <b-card-img-lazy src="/img/dreamtime/oem/cn/1.jpg" class="rounded-0"></b-card-img-lazy>   
    <b-card-img-lazy src="/img/dreamtime/oem/cn/2.jpg" class="rounded-0"></b-card-img-lazy>      
    <b-card-img-lazy src="/img/dreamtime/oem/cn/3.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/oem/cn/4.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/oem/cn/5.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/oem/cn/6.jpg" class="rounded-0"></b-card-img-lazy>    
    </div>
  <div v-else>
    <b-card-img-lazy src="/img/dreamtime/oem/en/1.jpg" class="rounded-0"></b-card-img-lazy>   
    <b-card-img-lazy src="/img/dreamtime/oem/en/2.jpg" class="rounded-0"></b-card-img-lazy>      
    <b-card-img-lazy src="/img/dreamtime/oem/en/3.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/oem/en/4.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/oem/en/5.jpg" class="rounded-0"></b-card-img-lazy>    
    <b-card-img-lazy src="/img/dreamtime/oem/en/6.jpg" class="rounded-0"></b-card-img-lazy> 
  </div>
    <Foot-section></Foot-section>
  </div>
</template>

<script>
import HeadSection from "./HeadSection.vue";
import FootSection from "./FootSection.vue";
import banner from "../assets/banner1.jpg";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "OEM",
  components: {
    HeadSection,
    FootSection
  },
  metaInfo() {
    return {
       title: this.$t("message.Bluedio_Mall"),
       titleTemplate: "%s",
      meta: [
         {
          name: "keywords",
          content: "OEM",
        }
      ],
    };
  },
  data() {
    return {
      banner_url:banner,
      
    };
  },
  created() {
    
  },
  computed: {
    ...mapGetters([
      "lan","iszh"
    ])
  },

  watch: {
  },
  methods: {
  },
};
</script>
